import request from '@/utils/request'


// 查询销售订单列表
export function listSell(query) {
  return request({
    url: '/order/sell/list',
    method: 'get',
    params: query
  })
}

// 查询销售订单分页
export function pageSell(query) {
  return request({
    url: '/order/sell/page',
    method: 'get',
    params: query
  })
}
export function pageOffline(query) {
  return request({
    url: '/order/sell/pageOffline',
    method: 'get',
    params: query
  })
}
// 查询销售订单详细
export function getSell(data) {
  return request({
    url: '/order/sell/detail',
    method: 'get',
    params: data
  })
}
// 查询销售订单详细
export function getSellOffline(data) {
  return request({
    url: '/order/sell/detailOffline',
    method: 'get',
    params: data
  })
}
export function cancelSell(data) {
  return request({
    url: '/order/sell/cancel',
    method: 'post',
    data: data
  })
}
export function writeOffSell(data) {
  return request({
    url: '/order/sell/WriteOff',
    method: 'post',
    data: data
  })
}

export function ordersSell(data) {
  return request({
    url: '/order/sell/orders',
    method: 'post',
    data: data
  })
}

export function deliverySell (data) {
  return request({
    url: '/order/sell/delivery',
    method: 'post',
    data: data
  })
}

// 导入待发货
export function importData (data) {
  return request({
    url: '/order/sell/delivery/import',
    method: 'post',
    data: data
  })
}

//物流信息
export function logistics(data) {
  return request({
    url: '/order/sell/logistics',
    method: 'get',
    params: data
  })
}

